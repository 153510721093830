import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  FaDiscord,
  FaTwitter,
  FaGithub,
  FaInstagram
} from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Permíteme <span className="purple"> presentarme </span> aquí:
            </h1>
            <p className="home-about-body">
              Me llamo <b className="purple"> Tazz </b> soy un programador especializado en desarrollo de proyectos con
              <b className="purple"> NodeJS, Python y C#. </b>
              <br />
              <br />Aunque manejo otros lenguajes que podras
              encontrar en el apartado <b className="purple"> Sobre Mi. </b>

              <br />
              <br />
              Me intereso en el &nbsp;
                <b className="purple">Desarrollo Web </b>y proyectos con
                tecnologías nuevas como actualmente con{" "}
                <b className="purple">
                  DiscordJS.
                </b>
              <br />
              <br />
              Normalmente siempre estoy realizando proyectos propios
              con <b className="purple">Node.js</b> ,
                <b className="purple">
                  {" "}
                  Librerias </b>y <b className="purple">Frameworks </b>actuales de <b className="purple">JavaScript
                </b>
              &nbsp; aunque usé
                <b className="purple"> React.js y Next.js </b> para este portafolio.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Contáctame</h1>
            <p>
              podrás <span className="purple">escribirme </span>aquí:
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://discord.tazzdeveloper.online"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaDiscord />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/SearchZ_"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/TazzUser"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kryptix.01"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
