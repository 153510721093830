import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mis recientes <strong className="purple">Proyectos </strong>
        </h1>
        <p style={{ color: "white" }}>
          Estos son algunos de los proyectos en los que he contribuido.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="OtterPixel Studio"
              description="Proyecto personal de la par con personas en el mismo rubro, para este proyecto fue necesario realizar no solo la configuración completa del Servidor de Discord sino así mismo crear un Bot desde el inicio para sistemas como control de roles , permisos , seguridad , moderación , diversión y tickets.
              En el que fue necesario usar DiscordJS y librerias de afines. En sintesis, un proyecto ambisioso que no solo genera interacción de personas dentro de Discord sino que también significa diseñar productos con continuidad para ser publicados."
              ghLink="https://discord.gg/YxbuEFU6h9"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Minepolis Newtork"
              description="Proyecto en el cual estuve desde el inicio del mismo, junto con zNinjaGOD, en el que no solo cree un sistema de Interación entre jugadores sino cree toda la Network y modalidades las cuales actualmente siguen en funcionamiento, el trabajo más arduo fue crear la Tienda Web donde emplee Next, React y JavaScript igualmente para el Foro y Pagina Ecommerce de Mercancia."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Embed Builder"
              description="Visualice el contenido proporcionado mediante el JSON o el editor GUI, esto se puede utilizar para comandos de insercción del bot de Discord asi mismo, también se puede integrar en el sitio web de su bot de Discord, puedes editar en JSON y visualizarlo en el editor GUI y viceversa. Aquí tan solo se utilizó JavaScript, HTML5 y CSS."
              ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Kyori"
              description="Trabaje los ultimos meses en su Network, donde instale Pterodactyl y desarrolle un Dashboard personalizado desde 0 utilizando CSS para reemplazar con el que cuenta Pterdactyl por defecto, actualmente solamente les doy soporte a su Network."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Portafolio Personal"
              description="Proyecto personal que usaré en un futuro y actualmente esta incompleto, estoy usando React, Next, CSS y JavaScript además resalto que es un pagina responsive por lo que esta perfectamente bien para todos los dispositivos, en un futuro la haré Open Source para todos los interesados."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Template WebStore"
              description="Un Diseño simple de una pagina E-commerce totalmente customisable, donde emplee JavaScript, CSS, HTML5, Next y React totalmente responsive, desarrollada en conjunto con spaceley un gran developer."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
