import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hola a Todos, Soy <span className="purple">Tazz Developer </span>
            de <span className="purple"> Latinoamérica.</span>
            <br /> Soy estudiante universitario y programador con <span className="purple">3 años </span>
            de experiencia reflejados en mis proyectos realizados.
            <br />
            Adicional a esto tengo <span className="purple">18 años </span> y estudio Ingenieria de Software
            , asi mismo he complementado mis conocimientos con cursos como <span className="purple">SoyHenry y Udemy. </span>
            <br />
            <br />
            Además de programar tengo otras actividades favoritas como:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Jugar Videojuegos
            </li>
            <li className="about-activity">
              <ImPointRight /> Leer Libros
            </li>
            <li className="about-activity">
              <ImPointRight /> Viajar
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "¡La vida se trata de metas, mientras más metas mejor!"{" "}
          </p>
          <footer className="blockquote-footer">Tazz Developer</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
